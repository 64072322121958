<template>
    <div class="main_container">
        <div class="manage_container">
            <div class="manage">类型:</div>
            <span>{{auditList[auditType].label}}</span>
        </div>
        <div class="manage_container">
            <div class="manage">修改出处:</div>
            <span>{{this.$store.state.user.detail.book1&&this.$store.state.user.detail.book1.publishing
                || this.$store.state.user.detail.book2&&this.$store.state.user.detail.book2.publishing}}</span>
        </div>
        <div class="manage_container">
            <div class="manage">修改内容:</div>
            <div class="table_container">
                <div class="top_container">
                    <div class="left_container">修改前</div>
                    <div class="right_container">修改后</div>
                </div>
                <div class="bottom_container">
                    <div class="left_container">
                        <div class="line_class"><span class="title_text">学期:</span>{{this.$store.state.user.detail.book1
                            &&
                            this.$store.state.user.detail.book1.semesterName}}
                        </div>
                        <div class="line_class"><span class="title_text">出版社:</span>{{this.$store.state.user.detail.book1
                            &&
                            this.$store.state.user.detail.book1.publishing}}
                        </div>
                        <div class="line_class"><span class="title_text">封面:</span>
                            <img :src="this.$store.state.user.detail.book1 && getUrl(this.$store.state.user.detail.book1.coverUrl)"
                                 alt="">
                        </div>
                    </div>
                    <div class="right_container">
                        <div class="line_class"><span class="title_text">学期:</span>{{this.$store.state.user.detail.book2
                            &&
                            this.$store.state.user.detail.book2.semesterName}}
                        </div>
                        <div class="line_class"><span class="title_text">出版社:</span>{{this.$store.state.user.detail.book2
                            &&
                            this.$store.state.user.detail.book2.publishing}}
                        </div>
                        <div class="line_class"><span class="title_text">封面:</span>
                            <img :src="this.$store.state.user.detail.book2 && getUrl(this.$store.state.user.detail.book2.coverUrl)"
                                 alt="" style="width: 150px">
                        </div>


                    </div>
                </div>
            </div>
        </div>
        <div class="manage_container">
            <div class="manage">上传时间:</div>
            <span>  {{createTimeHandler(this.time)}}</span>
        </div>
        <el-button type="primary" class="confirm_button" @click="onConfirm">通过</el-button>
        <el-button type="danger" @click="oncancel">拒绝</el-button>
    </div>

</template>

<script>
    import axios from '@/libs/axios'
    import {compressFile} from "@/utils/fileUtil";
    import {getData} from '@/utils/cookies'
    import {getDateFromTimeStamp} from "@/libs/tools";

    export default {
        name: "add",
        data() {
            return {
                auditList: [
                    {type: 1, label: '上传Excel'}, {type: 2, label: '添加教材'},
                    {type: 3, label: '删除教材'}, {type: 4, label: '更新教材'},
                    {type: 5, label: '添加单元'}, {type: 6, label: '删除单元'},
                    {type: 7, label: '更新单元'}, {type: 8, label: '添加章节'},
                    {type: 9, label: '删除章节'}, {type: 10, label: '更新章节'},
                    {type: 11, label: '添加字词'}, {type: 12, label: '删除字词'},
                    {type: 13, label: '更新字词'}
                ],
                auditType: 2,//审核类型
                auditName: '',//审核出处
                id: '',//操作id
                time: '',//创建时间
            }
        },
        mounted() {
            console.log(getData('auditInfo'));
            this.auditType = getData('auditInfo').type - 1
            this.auditName = getData('auditInfo').name
            this.time = getData('auditInfo').createTime
            this.id = getData('auditInfo').id
            this.getAuditDetail()
        },
        methods: {
            getUrl(url) {
                return axios.getBookUrl(url);
            },
            //确认添加
            onConfirm() {
                this.$store.dispatch("user/audit", {
                    'id': this.id,
                    'pass': true
                }).then(() => {
                    this.$router.go(-1);
                });
            },
            //取消添加
            oncancel() {
                this.$store.dispatch("user/audit", {
                    'id': this.id,
                    'pass': false
                }).then(() => {
                    this.$router.go(-1);
                });
            },
            imageUpload(request) {
                this.loading = true;
                compressFile(request.file, (file) => {
                    axios.upload("/v1/web/file/book", file).then(response => {
                        this.localImageUrl = URL.createObjectURL(file);
                        this.imageUrl = response.data.data.url;
                        this.loading = false;
                        console.log(this.imageUrl);
                        console.log(this.localImageUrl);
                    }).catch((e) => {
                        console.log(e)
                    });
                })
            },
            //获取修改详情
            getAuditDetail() {
                this.$store.dispatch("user/detailList", {
                    'id': this.id
                }).then((data) => {

                })
            },
            //时间戳转换工具
            createTimeHandler(timeStamp) {
                return getDateFromTimeStamp(timeStamp)
            },
        }
    }
</script>
<style scoped lang="scss">
    .main_container {
        width: 100%;
        padding: 20px;

        .manage_container {
            display: flex;
            align-items: center;
            margin-bottom: 30px;
            font-size: 16px;

            .manage {
                width: 150px;
                text-align: right;
                margin-right: 30px;
            }

            .manage_input {
                display: inline-block;
                width: 300px;
            }
        }

        .manage {
            width: 150px;
            text-align: right;
        }

        .confirm_button {
            margin-left: 100px;
            margin-right: 50px;
            margin-top: 100px;
        }

        .table_container {
            width: 80%;
            height: auto;
            border: 1px solid #E4E7ED;
            color: #606266;
        }

        .top_container {
            width: 100%;
            height: 40px;
            display: flex;
        }

        .left_container {
            flex: 1;
            border: 1px solid #E4E7ED;
            line-height: 40px;
            text-align: center;
        }

        .right_container {
            flex: 1;
            border: 1px solid #E4E7ED;
            line-height: 40px;
            text-align: center;
        }

        .bottom_container {
            width: 100%;
            min-height: 100px;
            display: flex;
        }

        .line_class {
            display: flex;
            align-items: center;
        }

        .img_container {
            display: flex;
            align-items: center;
        }

        .title_text {
            width: 40%;
            font-size: 14px;
            color: #409EFF;
            margin-right: 10px;
            text-align: end;
        }
    }

</style>
